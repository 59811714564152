<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle is-5 has-text-primary">
              <span>{{ data.header.label }}</span>
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-primary" @click="close">Close</a>
          </div>
        </div>
        <div>
          <p>{{ data.cell }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MultiCellFocus',
  computed: {
    ...mapState({
      data: state => state.modal.config
    })
  },
  methods: {
    close() {
      this.$modal.close()
    }
  }
}
</script>
